import { Injectable } from '@angular/core';

import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';

@Injectable({ providedIn: 'root' })
export class NotificationService {
  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(private readonly snackBar: MatSnackBar) {}

  success(message: string) {
    this.openSnackBar(message, '', 'success');
  }

  error(message: string) {
    this.openSnackBar(message, '', 'error');
  }

  openSnackBar(
    message: string,
    action: string,
    className = '',
    duration = 3000,
  ) {
    this.snackBar.open(message, action, {
      duration: duration,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: ['snackbar', className],
    });
  }
}
