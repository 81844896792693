import { HttpClient } from '@angular/common/http';
import { Injectable, computed, signal } from '@angular/core';
import { Observable } from 'rxjs';

import {
  TChangePasswordRequest,
  TChangeProfileRequest,
  TConfirmRequest,
  TProfileData,
} from '@models/profileTypes';
import {
  TSetEmailRequest,
  TSetPhoneRequest,
} from '@components/pages/registration/core/registrationTypes';
import { NotificationService } from './notification.service';
import { TBooleanResponse } from '@models/authTypes';

import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class ProfileService {
  // Профиль пользователя
  #profileData = signal<TProfileData | null>(null, {
    // equal: (a, b) => a?.id === b?.id,
  });
  profileData = computed(this.#profileData);

  #loading = signal<boolean>(false);
  loading = computed(this.#loading);

  #error = signal<string | null>(null);
  error = computed(this.#error);

  #emailConfirmed = signal<boolean>(true);
  emailConfirmed = computed(this.#emailConfirmed);

  #phoneConfirmed = signal<boolean>(true);
  phoneConfirmed = computed(this.#phoneConfirmed);

  constructor(
    private http: HttpClient,
    private notificationService: NotificationService,
  ) {}

  loadProfile(): Observable<TProfileData> {
    return this.http.get<TProfileData>(
      `${environment.getApiVersionUrl('user/profile')}`,
    );
  }

  editProfile(data: TChangeProfileRequest): Observable<TProfileData> {
    return this.http.put<TProfileData>(
      `${environment.getApiVersionUrl('user/profile')}`,
      { ...data },
    );
  }

  editProfileAvatar(avatar: string): Observable<TProfileData> {
    return this.http.put<TProfileData>(
      `${environment.getApiVersionUrl('user/avatar')}`,
      { avatar },
    );
  }

  changePassword(data: TChangePasswordRequest): Observable<TBooleanResponse> {
    return this.http.put<TBooleanResponse>(
      `${environment.getApiVersionUrl('user/change-password')}`,
      { ...data },
    );
  }

  // Запросить смену телефона
  requestChangePhone(data: TSetPhoneRequest): Observable<TBooleanResponse> {
    return this.http.post<TBooleanResponse>(
      `${environment.getApiVersionUrl('user/request-change-phone')}`,
      { ...data },
    );
  }

  // Подтвердить смену телефона
  confirmChangePhone(data: TConfirmRequest): Observable<TBooleanResponse> {
    return this.http.put<TBooleanResponse>(
      `${environment.getApiVersionUrl('user/confirm-change-phone')}`,
      { ...data },
    );
  }

  // Запросить смену email
  requestChangeEmail(data: TSetEmailRequest): Observable<TBooleanResponse> {
    return this.http.post<TBooleanResponse>(
      `${environment.getApiVersionUrl('user/request-change-email')}`,
      { ...data },
    );
  }

  // Подтвердить смену email
  confirmChangeEmail(data: TConfirmRequest): Observable<TBooleanResponse> {
    return this.http.put<TBooleanResponse>(
      `${environment.getApiVersionUrl('user/confirm-change-email')}`,
      { ...data },
    );
  }

  setEmailConfirmed(newValue: boolean) {
    this.#emailConfirmed.set(newValue);
  }

  setPhoneConfirmed(newValue: boolean) {
    this.#phoneConfirmed.set(newValue);
  }

  resetProfileData(): void {
    this.#profileData.set(null);
  }

  setProfileData(data: TProfileData): void {
    this.#profileData.set(data);
  }

  setLoading(newValue: boolean) {
    this.#loading.set(newValue);
  }

  setError(newValue: string | null) {
    this.#error.set(newValue);
  }
}
